<template>
    <div class="w-full inline-flex gap-4 flex-nowrap overflow-x-hidden">
        <div class="entries">
            <slot name="testimonials"></slot>
        </div>
        <div aria-hidden="true" class="entries">
            <slot name="testimonials"></slot>
        </div>
    </div>
</template>

<style scoped type="postcss">
.entries {
    @apply flex flex-row gap-4 items-center justify-stretch animate-infinite-scroll;
}
</style>
