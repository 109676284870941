<template>
    <div class="relative h-screen w-screen overflow-auto md:overflow-hidden">
        <div class="bubble bubble-left"></div>
        <div class="bubble bubble-right"></div>
        <Globe class="globe" />
        <div class="relative z-20 max-w-screen-xl mx-auto h-auto md:h-full w-full flex flex-col items-center md:grid md:grid-cols-12">
            <div class="flex flex-col gap-6 md:col-span-4 md:justify-center p-10 md:pl-[60px] md:pr-[30px] md:py-[60px]">
                <div>
                    <img height="36" class="h-9 w-auto mx-auto block" :src="organization_logo_url || '/static/logo.png'" />
                </div>
                <header class="tracking-tighter flex text-center flex-col gap-2">
                    <h1 class="font-semibold text-2xl leading-none text-gray-800">
                        <template v-if="organization_name">
                            Superpowers for<br />
                            leaders at {{ organization_name }}
                        </template>
                        <template v-else>
                            Superpowers for <br />
                            Fortune 500 leaders
                        </template>
                    </h1>
                    <p class="px-4 text-base font-medium text-gray-400">Nadia is here to make your work life a little bit easier.</p>
                </header>
                <Transition name="fade" mode="out-in">
                    <ul v-if="hasNonFormErrors" class="error-list">
                        <li v-if="errors.server_error" v-text="errors.server_error"></li>
                        <li v-else-if="errors.unrecognized_email" v-text="errors.unrecognized_email"></li>
                        <li v-else-if="errors.query" v-text="errors.query"></li>
                    </ul>
                </Transition>

                <form ref="formRef" class="flex flex-col gap-10 items-center" @submit.prevent="onSubmit">
                    <div class="form-control">
                        <div class="flex flex-row items-center gap-2">
                            <input id="email-control" v-model="email" type="email" placeholder="Email" class="form-control-input" data-cy="email" />
                            <button type="submit" data-cy="submit">
                                <i class="hover:text-[#4B508F] transition-colors ease-out-in duration-150 bi bi-arrow-right-circle text-[#555BA2] text-2xl" />
                            </button>
                        </div>
                        <Transition name="fade" mode="out-in">
                            <ul v-if="formFieldHasError('email')" class="error-list">
                                <template v-for="(err, i) in errors.form.email" :key="i">
                                    <li v-text="err"></li>
                                </template>
                            </ul>
                        </Transition>
                    </div>
                    <Transition name="fade" mode="out-in">
                        <div
                            v-if="email_sent"
                            class="mx-auto w-4/5 text-center text-xs font-medium text-[#8C8C8C] tracking-tighter text-balance rounded-lg px-2 py-3 bg-[#f5f5f5] ring-2 ring-inset ring-[#e8e8e8]"
                        >
                            Check your email for a magic sign-in link. Don&apos;t see it?
                            <button type="button" class="text-[#555BA2] inline-block" @click="onResend">Resend it.</button>
                        </div>
                    </Transition>
                    <Transition name="fade" mode="out-in">
                        <div v-if="should_show_sso_option" class="flex flex-col gap-2 w-4/5">
                            <button type="button" class="button button-primary" @click="useSSO = true">Sign in with SSO</button>
                            <button type="button" class="button button-inverted" @click="useSSO = false">Email link</button>
                        </div>
                    </Transition>
                    <Transition name=" fade" mode="out-in">
                        <div v-if="should_show_terms" class="form-control">
                            <div class="flex flex-row items-start gap-2 rounded-lg px-2 py-3 bg-[#f5f5f5] ring-2 ring-inset ring-[#e8e8e8]">
                                <input id="terms-control" v-model="terms" type="checkbox" tabindex="-1" class="hidden" />
                                <i
                                    ref="checkboxRef"
                                    :aria-checked="terms"
                                    tabindex="0"
                                    aria-labelledby="terms-control-label"
                                    role="checkbox"
                                    :class="terms ? 'bi-check-circle-fill' : 'bi-circle'"
                                    class="shrink-0 bi text-xl text-[#7A60FD] hover:text-[#4B508F] transition-colors duration-150 ease-in"
                                    @click="terms = !terms"
                                />
                                <label id="terms-control-label" class="grow leading-none" for="terms-control">
                                    <span
                                        v-if="organizationTermsHTML"
                                        class="text-balance *:text-xs *:tracking-tighter *:font-medium *:text-[#8C8C8C]"
                                        v-html="organizationTermsHTML"
                                    >
                                    </span>
                                    <span v-else class="text-xs font-medium text-[#8C8C8C] tracking-tighter text-balance">
                                        I confirm that I have read the Valence
                                        <a class="underline" target="_blank" href="https://app.valence.co/agreements/terms">Terms of Service</a>&nbsp; and
                                        <a target="_blank" href="https://www.valence.co/other/privacy">Privacy Policy</a>&nbsp; and give my consent for sharing my personal data in
                                        the scope of this service.
                                    </span>
                                </label>
                            </div>
                            <Transition name="fade" mode="out-in">
                                <ul v-if="formFieldHasError('terms')" class="error-list">
                                    <template v-for="(err, i) in errors.form.terms" :key="i">
                                        <li v-text="err"></li>
                                    </template>
                                </ul>
                            </Transition>
                        </div>
                    </Transition>
                </form>
                <div v-if="googleLoaded" class="grid grid-cols-12 items-center">
                    <div class="col-span-5 w-full h-0.5 bg-gray-200"></div>
                    <div class="col-span-2 font-semibold text-lg text-gray-500 text-center">or</div>
                    <div class="col-span-5 w-full h-0.5 bg-gray-200"></div>
                </div>
                <div v-show="googleLoaded">
                    <div id="signinWithGoogle"></div>
                    <div id="g_id_onload" :data-state="googleState" :data-client_id="google_client_id" :data-login_uri="google_login_uri" data-auto_prompt="false"></div>
                    <div
                        class="g_id_signin"
                        data-type="standard"
                        data-size="large"
                        data-theme="outline"
                        data-text="sign_in_with"
                        data-shape="rectangular"
                        data-logo_alignment="center"
                    ></div>
                </div>
            </div>
            <TestimonialCarousel class="pb-10 md:hidden">
                <template #testimonials>
                    <Testimonial
                        v-if="testimonials[0]"
                        class="row-element w-72"
                        :organization-name="organization_name"
                        :attribution="testimonials[0].attribution"
                        :quote="testimonials[0].quote"
                        :avatar-url="testimonials[0].avatar_url"
                    />
                    <Testimonial
                        v-if="testimonials[1]"
                        class="row-element w-72"
                        :organization-name="organization_name"
                        :attribution="testimonials[1].attribution"
                        :quote="testimonials[1].quote"
                        :avatar-url="testimonials[1].avatar_url"
                    />
                </template>
            </TestimonialCarousel>
            <div class="rh-grid">
                <div v-if="testimonials[0]" class="row rh-row-top">
                    <Testimonial
                        class="row-element z-10 row-content--top"
                        :organization-name="organization_name"
                        :attribution="testimonials[0].attribution"
                        :quote="testimonials[0].quote"
                        :avatar-url="testimonials[0].avatar_url"
                    />
                    <img :src="testimonials[0].image_url" class="row-top-image row-element" />
                </div>
                <div v-if="testimonials[1]" class="row rh-row-bottom">
                    <Testimonial
                        class="row-element z-10 row-content--bottom"
                        :organization-name="organization_name"
                        :attribution="testimonials[1].attribution"
                        :quote="testimonials[1].quote"
                        :avatar-url="testimonials[1].avatar_url"
                    />
                    <img :src="testimonials[1].image_url" class="row-bottom-image row-element" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { router } from "@inertiajs/vue3";
import { useScriptTag } from "@vueuse/core";
import Globe from "~vue/Onboarding/Globe.vue";
import Testimonial from "~vue/Onboarding/Testimonial.vue";
import TestimonialCarousel from "~vue/Onboarding/TestimonialCarousel.vue";
import { toMarkdown } from "~vue/utils.js";
import { computed, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";

const props = defineProps({
    organization_name: String,
    organization_logo_url: String,
    organization_terms_content: String,
    google_client_id: String,
    google_login_uri: String,
    should_show_terms: Boolean,
    should_show_sso_option: Boolean,
    email: String,
    email_sent: Boolean,
    errors: Object,
    testimonials: Array,
});

const email = ref(props.email || "");
const terms = ref(false);
const useSSO = ref(undefined);
const googleLoaded = ref(false);

const formRef = useTemplateRef("formRef");
const checkboxRef = useTemplateRef("checkboxRef");

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const googleState = JSON.stringify({ timezone: userTimezone });

const hasErrors = computed(() => props.errors !== null && Object.keys(props.errors).length > 0);
const hasNonFormErrors = computed(() => hasErrors.value && !props.errors.form);
const organizationTermsHTML = computed(() => (props.organization_terms_content ? toMarkdown(props.organization_terms_content) : ""));

if (props.google_client_id) {
    /*
     * Load Google OAuth only when the backend passes an id. The DOM
     * is nonetheless there hidden with a v-show as the loaded script
     * needs to retrieve some necessary attributes.
     */
    useScriptTag("https://accounts.google.com/gsi/client", () => {
        googleLoaded.value = true;
    });
}

watch(
    () => props.email,
    (val, prev) => {
        /*
         * Reset form values if email changed. We used the last email that was validated by the backend,
         * as opposed to the current email value on the client.
         */
        if (prev && val !== prev) {
            terms.value = false;
            useSSO.value = undefined;
        }
    },
);

watch(useSSO, () => {
    formRef.value.dispatchEvent(new Event("submit"));
});

onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
});

onUnmounted(() => {
    document.addEventListener("keydown", handleKeydown);
});

function handleKeydown(event) {
    /* We're using a custom checkbox so we have to reimplement the default
     * keyboard bindings afforded by the native control.
     */
    const supportedKeys = ["Enter", "Space"];

    if (supportedKeys.indexOf(event.code) === -1 || document.activeElement !== checkboxRef.value) {
        return;
    }

    terms.value = !terms.value;
}

function formFieldHasError(field) {
    return hasErrors.value && props.errors.form && Object.hasOwn(props.errors.form, field);
}

function postResponse(payload, propsToHydrate = []) {
    router.post("", payload, {
        only: propsToHydrate,
        headers: {
            "X-CSRFToken": getCookie("csrftoken"),
        },
    });
}

function onResend() {
    postResponse({ intent: "resend_link", form: { email: email.value } }, ["email_sent", "errors"]);
}

function onSubmit() {
    let formValue = {
        email: email.value,
    };

    /*
     * Only pass the subsequent form values after email when
     * the last email validated by the server matches the current
     * email input value.
     */
    if (props.email === formValue.email) {
        if (props.should_show_terms || props.should_show_sso_option) {
            formValue.terms = terms.value;
        }

        if (props.should_show_sso_option) {
            formValue.chose_sso = useSSO.value;
        }
    }

    postResponse(
        {
            intent: "authenticate",
            user_timezone: userTimezone,
            form: formValue,
        },
        ["email", "email_sent", "errors", "should_show_terms", "should_show_sso_option"],
    );
}
</script>

<style scoped type="postcss">
.globe {
    transform: scale(1.3) rotate(12deg) translateX(25%);
    @apply absolute top-0 right-0 h-screen hidden md:block;
}

.bubble {
    @apply md:hidden fixed overflow-hidden h-screen w-screen top-auto bottom-0 rounded-full;
    backdrop-filter: blur(2px);
}

.bubble-right {
    transform: scale(1.5) translateY(30%) translateX(30%);
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 63, 220, 0.12) 0%, rgba(0, 63, 220, 0) 100%);
}

.bubble-left {
    transform: scale(1.5) translateY(30%) translateX(-30%);
    background: radial-gradient(50% 50% at 50% 50%, rgba(254, 40, 144, 0.12) 0%, rgba(254, 40, 144, 0) 100%);
}

.rh-grid {
    @apply hidden pb-10 md:pb-0 col-span-12 md:grid md:col-span-8 w-full flex flex-row items-center justify-stretch md:gap-0;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 12px;
}

.rh-row-top {
    @apply md:pt-6 md:mt-auto md:mx-auto md:mb-0;
    grid-area: 1 / 2 / 2 / 8;
}

.rh-row-bottom {
    @apply md:pb-6 md:mt-0 md:mx-auto md:mb-auto;
    grid-area: 2 / 4 / 3 / 11;
}

.rh-row-top,
.rh-row-bottom {
    @apply md:max-h-[350px];
}

.row {
    @apply shrink-0 w-64 md:w-auto md:grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.row-content--top {
    grid-area: 4 / 1 / 8 / 7;
}

.row-top-image {
    grid-area: 1 / 3 / 5 / 9;
}

.row-content--bottom {
    @apply md:items-end md:text-right;
    grid-area: 4 / 4 / 8 / 9;
}

.row-bottom-image {
    grid-area: 1 / 2 / 5 / 7;
}

.row-element {
    @apply border-2 border-gray-200 rounded-3xl;
}

.row-top-image,
.row-bottom-image {
    @apply hidden md:block z-20 object-cover w-full h-full block;
}

.row-content-quote {
    @apply font-semibold leading-none tracking-tighter text-lg text-gray-500;
}

.form-control {
    @apply flex flex-col gap-1;
}

.form-control-input {
    @apply w-full p-3 leading-none outline-[#555BA2] rounded-xl border-2 tracking-tighter text-base font-semibold text-gray-600 border-[#E8E8E8];
}

.form-control a {
    @apply text-[#555BA2];
}

.error-list li {
    @apply text-xs tracking-tighter font-medium text-red-700 text-center;
}

.button {
    @apply transition-colors ease-in duration-150 rounded-[20px] p-3 leading-none font-semibold tracking-tighter text-base w-full ring-2 ring-inset;
}

.button-primary {
    @apply bg-[#555BA2] hover:bg-[#4B508F] hover:ring-[#4B508F] text-white ring-[#555BA2];
}

.button-inverted {
    @apply bg-white text-[#555BA2] hover:text-[#4B508F] hover:bg-[#555BA2]/5 hover:ring-[#4B508F] ring-[#555BA2];
}

.g_id_signin *[role="button"] {
    @apply rounded-full;
}

fade-enter-active,
.fade-leave-active {
    transition: opacity 200 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
